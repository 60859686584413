<template>
  <div>
    <b-modal
      v-model="deleteElement"
      :title="$t('Delete Check')"
      :ok-title="$t('Delete')"
      :cancel-title="$t('cancel')"
      ok-variant="danger"
      cancel-variant="primary"
      @close="deleteElement=false"
      @cancel="deleteElement=false"
      @ok="ConfirmDelete"
    >
      <b-badge
        class="mt-1"
        variant="light-danger"
      >
        {{ $t('are you sure you want to delete this Sale') }}
      </b-badge>
    </b-modal>
    <h3 class="mb-3">
      {{ $t('sales') }}
    </h3>
    <b-row>
      <b-col cols="6">
        <b-button
          v-if="haveAccess('Create Sale')"
          variant="gradient-success"
          @click="$router.push({name:'sale.create'})"
        >
          {{ $t('Create new') }}
        </b-button>
      </b-col>
      <b-col
        cols="6"
        class="d-flex justify-content-end"
      >
        <b-button
          v-if="haveAccess(['Show Sale Trash','Show Sale Trash Owner'])"
          variant="gradient-danger"
          @click="$router.push({name:'sale.trashed'})"
        >
          <font-awesome-icon
            :icon="['fas', 'trash']"
          />
          {{ $t('Trashed') }}
        </b-button>
      </b-col>
    </b-row>

    <b-card
      class="mt-5"
    >
      <b-card-title>
        <b-row>
          <b-col cols="8">
            <h3>
              {{ $t('sale') }}
            </h3>
          </b-col>
          <b-col
            cols="4"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('Type to Search')"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-title>
      <b-table
        v-if="items.length>0"
        :fields="fields"
        responsive="sm"
        :items="items"
        class="table-w-max"
      >
        <template #cell(id)="data">
          <b-badge
            pill
            variant="light-primary"
          >
            {{ data.item.no }}
          </b-badge>
        </template>
        <template
          #cell(created_at_updated_at)="data"
        >
          <div>
            <b-badge
              v-if="data.item.created_at"
              v-b-tooltip.hover="{title:data.item.created_at,variant:'success',placement:'top'}"
              variant="light-success"
            >
              <font-awesome-icon
                :icon="['fas', 'table']"
              />
              {{ data.item.created_from }}
            </b-badge>
            <b-badge
              v-if="data.item.updated_from"
              v-b-tooltip.hover="{ title:data.item.updated_from,variant:'warning',placement:'top'}"
              variant="light-warning"
              class="ml-1"
            >
              <font-awesome-icon
                :icon="['fas', 'calendar-week']"
              />
              {{ data.item.updated_from }}
            </b-badge>
          </div>
        </template>
        <template #cell(expiration_date)="data">
          <b-badge
            pill
            variant="light-primary"
          >
            <font-awesome-icon
              :icon="['fas', 'table']"
            />
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(owner_type)="data">
          <b-badge
            pill
            :variant="data.item.owner_type?'light-primary':'light-danger'"
          >
            {{ data.item.owner_type? 'خاصة':'عامة' }}
          </b-badge>
        </template>
        <template #cell(fuel_type)="data">
          {{ $t(data.item.fuel_type) }}
        </template>
        <template #cell(wheel)="data">
          <b-badge
            pill
            :variant="data.item.wheel?'light-success':'light-danger'"
          >
            {{ data.item.wheel? 'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(combustion)="data">
          <b-badge
            pill
            :variant="data.item.combustion?'light-success':'light-danger'"
          >
            {{ data.item.combustion? 'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(light)="data">
          <b-badge
            pill
            :variant="data.item.light?'light-success':'light-danger'"
          >
            {{ data.item.light? 'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(brake)="data">
          <b-badge
            pill
            :variant="data.item.brake?'light-success':'light-danger'"
          >
            {{ data.item.brake? 'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(options)="data">
          <div class="w-max">
            <router-link
              v-if="haveAccess('Update Sale')||haveElementAccess('Update Sale Owner',data.item.created_by_id)"
              class="text-success"
              :to="{name:'sale.edit',params: { sale: data.item.id }}"
            >
              <font-awesome-icon
                :icon="['fas', 'edit']"
              />
            </router-link>
            <a
              v-if="haveAccess('Delete Sale')||haveElementAccess('Delete Sale Owner',data.item.created_by_id)"
              class="ml-2 text-danger"
              @click="Delete(data.item.id)"
            >
              <font-awesome-icon
                :icon="['fas', 'trash']"
              />
            </a>
          </div>
        </template>
      </b-table>
      <b-row
        v-else
        class="d-flex justify-content-center"
      >
        <b-col
          cols="4"
        >
          <b-alert
            variant="danger"
            show
          >
            <div class="alert-body text-center">
              <span>{{ $t('No Data Available') }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mt-2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            per-page="10"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <explain />
  </div>
</template>

<script>

export default {
  data() {
    return {
      deleteElement: false,
      filter: null,
      element: null,
      currentPage: 1,
      totalRows: 0,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: this.$t('id') },
        { key: 'checkk', label: this.$t('checkk') },
        { key: 'checkk_data.chassis_number', label: this.$t('chassis_number') },
        { key: 'checkk_data.platee_id', label: this.$t('plate') },
        { key: 'owner_type', label: this.$t('owner_type') },
        { key: 'fuel_type', label: this.$t('fuel_type') },
        { key: 'color', label: this.$t('color') },
        { key: 'fee', label: this.$t('Fee') },
        { key: 'expiration_date', label: this.$t('expiration_date') },
        { key: 'brake', label: this.$t('brake') },
        { key: 'light', label: this.$t('light') },
        { key: 'combustion', label: this.$t('combustion') },
        { key: 'wheel', label: this.$t('wheel') },
        { key: 'owwner_data.owner', label: this.$t('owwner') },
        { key: 'notes', label: this.$t('notes') },
        { key: 'created_at_updated_at', label: this.$t('created_at_updated_at') },
        { key: 'options', label: this.$t('options') },
      ]
    },
    items() {
      return this.$store.getters['renew/GetElements']
    },
    pagination() {
      return `page=${this.currentPage}&search=${this.filter}`
    },
  },
  watch: {
    currentPage() {
      this.getElement()
    },
    filter() {
      this.currentPage = 1
      this.getElement()
    },
  },
  created() {
    this.getElement()
  },
  methods: {
    getElement() {
      this.$store.dispatch('renew/GetElements', `page=${this.currentPage}&search=${this.filter}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
      })
    },
    Delete(val) {
      this.deleteElement = true
      this.element = val
    },
    ConfirmDelete() {
      this.$store.dispatch('renew/Delete', this.element)
    },
  },
}
</script>
